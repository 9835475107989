import * as React from "react";

import Paragraph from "./Paragraph";
import EmbeddedAssets from "./EmbeddedAssets";

export default function Content({ rawBody, references }) {
  return rawBody.content.map((object) => {
    switch (object.nodeType) {
      case "paragraph":
        return (
          <Paragraph key={object.content[0].value}>{object.content}</Paragraph>
        );
      case "heading-2":
        return (
          <h2
            className="font-medium leading-tight text-3xl mt-0 mb-2 text-indigo-600"
            key={object.content[0].value}
          >
            {object.content[0].value}
          </h2>
        );
      case "unordered-list":
        return (
          <ul className="list-disc" key={object.content}>
            {object.content.map((listItem) => (
              <li key={listItem.content[0].content[0].value}>
                {listItem.content[0].content[0].value}
              </li>
            ))}
          </ul>
        );
      case "ordered-list":
        return (
          <ol className="list-decimal" key={object.content}>
            {object.content.map((listItem) => (
              <li key={listItem.content[0].content[0].value}>
                {listItem.content[0].content[0].value}
              </li>
            ))}
          </ol>
        );
      case "bold":
        return (
          <span className="font-bold" key={object.content}>
            {object.content[0].content[0].value}
          </span>
        );
      case "italic":
        return (
          <span className="italic" key={object.content}>
            {object.content[0].content[0].value}
          </span>
        );
      case "embedded-asset-block":
        return <EmbeddedAssets object={object} references={references} />;
      default:
        return null;
    }
  });
}

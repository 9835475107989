import * as React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export default function EmbeddedAssets({ object, references }) {
  const imgRefs = references.filter(
    (ref) => ref.contentful_id === object.data.target.sys.id
  );
  const images = imgRefs.map((imgRef) =>
    imgRef.localFile.childrenImageSharp
      .map((image) => ({
        childImageSharp: image,
        description: imgRef.description,
      }))
      .map((image) => (
        <GatsbyImage image={getImage(image)} alt={image.description} />
      ))
  );

  return images;
}

import * as React from "react";
import StyledText from "./StyledText";

export default function Paragraph({ children, disableMarginTop }) {
  return (
    <p
      className={`${
        disableMarginTop ? "" : "mt-8 "
      }mb-8 text-xl text-gray-500 leading-8`}
    >
      {children.map((child) => {
        switch (child.nodeType) {
          case "text":
            if (child?.marks.length > 0) {
              return <StyledText key={child.value}>{child}</StyledText>;
            } else {
              return <span key={child.value}>{child.value}</span>;
            }
          default:
            return null;
        }
      })}
    </p>
  );
}

import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

function Person({ person }) {
  return (
    <div className="space-y-4 sm:grid sm:grid-cols-3 sm:gap-6 sm:space-y-0 lg:gap-8">
      {person.profileImage && (
        <div className="h-0 aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-4">
          <GatsbyImage
            imgClassName="object-cover shadow-lg rounded-lg"
            image={getImage(person.profileImage.localFile)}
            alt={person.profileImage.description}
          />
        </div>
      )}
      <div className={person.profileImage ? "sm:col-span-2" : "sm:col-span-3"}>
        <div className="space-y-4">
          <div className="text-lg leading-6 font-medium space-y-1">
            <h3>
              {person.name}
              {person.role && <p className="text-indigo-600">{person.role}</p>}
            </h3>
          </div>
          {person.bio && (
            <div className="text-lg">
              <p className="text-gray-500">{person.bio.bio}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function SingleBio({ person, heading }) {
  return (
    <div className="bg-white">
      <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
        <div className="lg:grid lg:grid-cols-4">
          <div className="space-y-12 lg:col-start-2 lg:col-span-2">
            {heading && (
              <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">
                {heading}
              </h2>
            )}
            <Person person={person} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default function Bios({ people, heading }) {
  if (people.length === 1) {
    return <SingleBio person={people[0]} heading={heading} />;
  }

  return (
    <div className="bg-white">
      <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
        <div className="space-y-12">
          {heading && (
            <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">
              {heading}
            </h2>
          )}
          <ul className="space-y-12 lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-12 lg:gap-y-12 lg:space-y-0">
            {people.map((person) => (
              <Person key={person.id} person={person} />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Content from "@/components/Content";
import PageBody from "@/components/PageBody";
import CtaWithScreenshot from "../components/language/cta/CtaWithScreenshot";
import Bios from "../components/Bio";
import { defaultFields as leadFormFields } from "../config/ContactForm.data";
import { formatNumericDate } from "../utils.ts";
import Layout from "../components/Layout";
import { createSeoData } from "../utils/page";

export default function BlogPost({ data }) {
  const blogPostData = data.allContentfulBlogPost.edges[0].node;
  const seo = createSeoData({
    title: blogPostData.title,
    metaDescription: blogPostData.metaDescription,
    image: blogPostData.heroImage
      ? getImage(blogPostData.heroImage.localFile).images.fallback.src
      : null,
  });

  const hero = {
    type: "image",
    heroTitleLine1: blogPostData.title,
    heroContent: formatNumericDate(blogPostData.createdAt),
    heroImage: blogPostData.heroImage ? blogPostData.heroImage.localFile : null,
    heroImageAlt: blogPostData.heroImage
      ? blogPostData.heroImage.description
      : null,
    heroTextColor: blogPostData.heroTextColor,
  };

  return (
    <Layout seo={seo} hero={hero} leadFormFields={leadFormFields}>
      <PageBody limitMaxWidth>
        <Content
          rawBody={JSON.parse(blogPostData.body.raw)}
          references={blogPostData.body.references}
        />
      </PageBody>
      {blogPostData.authors && (
        <Bios heading="Written By" people={blogPostData.authors} />
      )}
      {blogPostData.author && (
        <Bios heading="Written By" people={[blogPostData.author]} />
      )}
      <CtaWithScreenshot
        headerLine1="Ready to begin your journey?"
        headerLine2="Schedule your first lesson now"
        ctaText="Select your class"
        imageUrl="/signUp.png"
        imageAlt="Sign up page on app screenshot"
      />
    </Layout>
  );
}

export const query = graphql`
  query ($slug: String!) {
    allContentfulBlogPost(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          slug
          title
          metaDescription
          heroTextColor
          body {
            raw
            references {
              contentful_id
              filename
              description
              localFile {
                childrenImageSharp {
                  gatsbyImageData(formats: [AUTO, WEBP])
                }
              }
            }
          }
          createdAt
          heroImage {
            description
            localFile {
              childImageSharp {
                gatsbyImageData(formats: [AUTO, WEBP])
              }
            }
          }
          authors {
            bio {
              bio
            }
            role
            id
            name
            profileImage {
              description
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 400, formats: [AUTO, WEBP])
                }
              }
            }
          }
          author {
            bio {
              bio
            }
            role
            id
            name
            profileImage {
              description
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 400, formats: [AUTO, WEBP])
                }
              }
            }
          }
        }
      }
    }
  }
`;

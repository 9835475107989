import * as React from "react";

export default function StyledText({ children }) {
  switch (children.marks[0].type) {
    case "bold":
      return (
        <span key={children.value} className="font-bold">
          {children.value}
        </span>
      );
    case "italic":
      return (
        <span key={children.value} className="italic">
          {children.value}
        </span>
      );
    default:
      return <span key={children.value}>{children.value}</span>;
  }
}
